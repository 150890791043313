<template>
  <div class="partners">
    <div class="container">
      <div class="partners-info">
        <h2>Hamkor tashkilotlar</h2>
        <el-carousel :interval="4000" :type="isMobile ? '' : 'card'" :height="isMobile ? '130px' : '170px'" indicator-position="outside" class="partners_carousel pt-5">
          <el-carousel-item>
            <img src="@/assets/images/img/kasaba.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img src="@/assets/images/img/sudishlari.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img src="@/assets/images/img/bosh_p.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img src="@/assets/images/img/IIV.jpg" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return this.windowWidth < 576
    },
    windowWidth() {
      return window.innerWidth
    }
  }
}

</script>

<style>
    .partners {
        padding: 50px 0;
    }
    .partners_carousel .el-carousel__container {
        height: 170px;
        z-index: 0;
    }
    .partners-info h2 {
        font-size: 35px;
        font-weight: 600;
        color: #151948;
    }
</style>
