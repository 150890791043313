<template>
  <div class="statistic">
    <div class="container">
      <h2 class="text-left">Statistika</h2>
      <div class="statistic-faq">
        <div class="statistic-number">
          <ul class="statistic-item">
            <li class="statistic-list ">
              <h5><span>323,152</span> ta</h5>
              <p class="pr-3">Kelib tushgan arizalar</p>
            </li>
            <li class="statistic-list statistic-border">
              <h5><span>23,152</span> ta</h5>
              <p>Ko'rib chiqilayotgan arizalar</p>
            </li>
            <li class="statistic-list statistic-border">
              <h5><span>3,152</span> ta</h5>
              <p>Tasdiqlangan arizalar</p>
            </li>
          </ul>
        </div>
        <img src="@/assets/images/img/statistic_icon.svg" width="400px" alt=""> 
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
    .statistic {
        background-color: #f9f9f9;
        padding: 50px 0;
    }
    .statistic-faq {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .statistic-item {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    /* .statistic-list {
        width: 32%;
        
    } */
    .statistic-border {
        border-left: 2px solid #D4D7D8;
        padding: 0px 10px 0 20px;
    }
    .statistic-list h5 {
        font-size: 18px;
        padding: 10px 0 5px 0;
        color: #1CAD6C;
    }
    .statistic-list span {
         font-size: 25px ;
         font-weight: 600;
    }
    .statistic-list p {
        font-size: 16px;
    }
    .statistic-number {
        background-color: white;
        padding: 30px;
        box-shadow: 0px 10px 60px rgba(137, 137, 137, 0.25);
        border-radius: 20px;
    }
    .statistic .text-left {
        font-size: 35px;
        font-weight: 600;
        color: #151948;
    }
</style>
