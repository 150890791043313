<template>
  <div class="home"> 
    <section id="business-plan">
      <div class="container">
        <div class="row">
          <!-- Start Col -->
          <div class="col-lg-6 col-md-12 pl-0 pt-70 pr-5">
            <div class="business-item-img">
              <img
                src="@/assets/images/img/business/business-img.png"
                class="img-fluid"
                alt=""
              >
            </div>
          </div>
          <!-- End Col -->
          <!-- Start Col -->
          <div class="col-lg-6 col-md-12">
            <div class="business-item-info">
              <h3>" Mehnat M " elektron platformasining maqsadi</h3>
              <p>
                Jismoniy va yuridik shaxslaming mehnat munosabatlaгi sohasidagi
                murojaatlarini qabul qilish, mehnat va mehnatni muhofaza qilish,
                mehnat huquqlari to'g'risidagi axborotlarni taqdim etish
                maqsadida tashkil etilgan elektron platforma.
              </p>

              <a class="btn btn-common" @click="goToRoute('AboutInfo')">Faoliyat</a>
            </div>
          </div>
          <!-- End Col -->
        </div>
      </div>
    </section>
    <Statistic />
    <section id="features" class="section faq">
      <div class="container">
        <!-- Start Row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="features-text section-header text-center">
              <div>
                <h2 class="section-title">Ko‘p beriladigan savollar</h2>
              </div>
            </div>
          </div>
        </div>
        <!-- End Row -->
        <!-- Start Row -->
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item
            title="Men qonun hujjatlari bilan eng kam oylik ish xaqi
                miqdori belgilanishini bilaman. Aytingchi, oylik ish haqining eng ko’p miqdori bo’yicha cheklovlar
                bormi?"
            name="1"
          >
            <h6>Javob:</h6>
            Yo’q, mavjud emas. Mehnat kodeksining 153-moddasi birinchi qismiga muvofiq mehnat haqining miqdori ish
            beruvchi bilan xodim o’rtasidagi kelishuvga binoan belgilanadi. Mehnat haqi qonun hujjatlari bilan
            belgilangan eng kam miqdordan oz bo’lishi mumkin emas va uning eng ko’p miqdori biron bir tarzda
            cheklanmaydi.
          </el-collapse-item>
          <el-collapse-item
            title="Vaqtinchalik moddiy qiynchiliklar bo’layotganligini vaj qilib ish beruvchi bizga ish haqini berishni
                kechiktirmoqda. Uning xarakatlari qonuniymi?"
            name="2"
          >
            <h6>Javob:</h6>
            Ish beruvchingizning xarakatlari noqonuniy. U Mehnat kodeksining 154-modda talablarini buzmoqda. Unga
            ko’ra ish beruvchi o’zining moliyaviy holatidan qat’i nazar, xodimga bajargan ishi uchun haqni
            belgilangan mehnat haqi shartlariga muvofiq Mehnat kodeksining161-moddasida ko’rsatilgan muddatlarda
            to’lashi shart
          </el-collapse-item>
          <el-collapse-item
            title="Bu oyda ish haqi olinadigan kun bayram kuniga to’g’ri kelgan. Shunga ko’ra buxgalter oylik ish haqini
                to’lanishini kechiktirish mumkinmi?"
            name="3"
          >
            <h6>Javob:</h6>
            Yo’q, mumkin emas. Haq to’lanadigan kun dam olish kuni yoki bayram kuniga to’g’ri kelib qolsa, mehnat
            haqi shu kun arafasida to’lanadi.
            Jamoa shartnomasida ish beruvchining aybi bilan xodimga haq to’lash belgilangan muddatlarga nisbatan
            kechikkanligi uchun javobgarlik nazarda tutilishi mumkin.
          </el-collapse-item>
          <el-collapse-item title="Tungi va kechgi vaqtdagi ish uchun haq to’lash tartibi qanday?" name="4">
            <h6>Javob:</h6>
            Mehnat kodeksining 158-moddasiga asosan, tungi vaqtdagi ishning har bir soati uchun kamida bir yarim
            baravar miqdorda haq to’lanadi.
            Jamoa shartnomasida, agar u tuzilmagan bo’lsa, kasaba uyushmasi qo’mitasi yoki xodimlarning boshqa
            vakillik organi bilan kelishib, kechki smenadagi ish uchun oshirilgan miqdorda haq to’lash nazarda
            tutilishi mumkin. Bevosita tungi smenadan oldingisi kechki smena deb hisoblanadi.
          </el-collapse-item>
          <el-collapse-item title="Mehnat shartnomasiga muvofiq menga 28 ish kunidan iborat mehnat ta’tili berilishi kerak. Men uni ikki qismga bo’lib olsam bo’ladimi?" name="5">
            <h6>Javob:</h6>
            Mehnat kodeksining 146-moddasi talablariga rioya qilgan holda xodimning xohishiga ko’ra uning yozma arizasi asosida ta’tilni qismlarga bo’lishga yo’l qo’yiladi. Bunda ta’tilning bir qismi o’n ikki ish kunidan kam bo’lmasligi lozim.
          </el-collapse-item>
        </el-collapse>
        <!-- End Row -->
      </div>
    </section>   
    <Partners />
    <!-- <div id="preloader">
      <div class="loader" id="loader-1"></div>
    </div> -->
  </div>
</template>

<script>
import Statistic from '../components/statistic'
import Partners from '../components/partners'
export default {
  name: 'Home',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Statistic, Partners
  },
  data() {
    return {
      activeName: '1'
    }
  }
  
}
</script>
<style scoped>
  .business-item-info {
    margin-bottom: 70px !important;
  }
</style>
